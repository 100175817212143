<template>
  <div class="flex flex-col">
    <div class="my-auto">
      <SharedBasicsDefaultImage
        source="/logo_word_negative.svg"
        alt="SEC AUDITOR logo"
        class=""
        img-classes="h-10 w-auto mx-auto mb-5"
      />
      <h1 class="text-secondary-lighter text-center font-bold mb-10">
        Initialisierung des Arbeitsbereiches
      </h1>
      <div class="">
        <Spinner class="w-5 h-5 text-secondary-lighter mx-auto" />
        <p class="text-secondary-light mt-5 px text-center">
          Der Arbeitsbereich wird aktuell eingerichtet. <br> Bitte haben Sie einen Moment Geduld.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang='ts'>
import Spinner from '~/components/shared/basics/Spinner.vue'
import { useCurrentClient } from '~/composables/states'

const { initializeCurrentClient, currentClient } = useCurrentClient()
const { $logger, $toast } = useNuxtApp()

// Check if the client is already initialized - if not start polling
if (!currentClient.value.client_initialized) {
  updateInitializedStatus()
}

async function updateInitializedStatus () {
  try {
    await initializeCurrentClient(currentClient.value.client_id_pk)

    if (currentClient.value.client_initialized) {
      $toast.success(`Arbeitsbereich für ${currentClient.value.client_name} erfolgreich initialisiert`)
    } else {
      setTimeout(() => {
        updateInitializedStatus()
      }, 1000)
    }
  } catch (error) {
    $logger.error(error)
    $toast.error('Fehler beim Initialisieren des Arbeitsbereichs')
  }
}

</script>

<style scoped>

</style>
